@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply font-open-sans;
  }

  h1 {
    @apply text-black font-bold text-[32px] leading-[37px];
  }

  h2 {
    @apply text-black-text font-normal text-[26px] leading-[32px];
  }

  h3 {
    @apply text-black-text font-semibold text-[20px] leading-[24px];
  }
}

@layer components {
  .heading-card-title {
    @apply text-black font-semibold text-[16px] leading-[19px];
  }

  .text {
    @apply text-black-text font-normal text-[16px] leading-[24px];
  }

  .text-hint {
    @apply text-black-text font-normal text-[14px] leading-[14px];
  }

  .text-comment {
    @apply text-black-text font-normal text-[14px] leading-[18px];
  }

  .micro-copy {
    @apply text-shadow-text font-normal text-[10px] leading-[15px];
  }

  .link {
    @apply text text-blue-mid hover:text-blue-deep normal-case;
  }

  .btn {
    @apply py-2 px-6 rounded hover:shadow-md text-white normal-case font-semibold text-[16px] leading-[20px];
  }

  .btn-primary {
    @apply bg-blue-light hover:bg-blue-deep border border-solid border-blue-light hover:border-blue-deep;
  }

  .btn-primary-outline {
    @apply bg-transparent hover:bg-blue-deep border border-solid border-blue-light hover:border-blue-deep text-black-text hover:text-white;
  }

  .btn-alert {
    @apply bg-red-alert hover:bg-red-700;
  }

  .btn-alert-outline {
    @apply bg-transparent hover:bg-transparent border border-solid border-red-alert hover:border-red-700 text-black-text;
  }

  .status {
    @apply px-3 py-1 rounded h-fit w-fit text-hint text-white;
  }

  .draft {
    @apply bg-shadow-text;
  }

  .submitted {
    @apply bg-blue-500;
  }

  .pending-approval {
    @apply bg-orange-alt;
  }

  .under-review {
    @apply bg-orange-alt;
  }

  .under-evaluation {
    @apply bg-orange-alt;
  }

  .published {
    @apply bg-blue-mid;
  }

  .gathering-ideas {
    @apply bg-blue-mid;
  }

  .idea-in-development {
    @apply bg-[#A3DDF7];
  }

  .final-evaluation {
    @apply bg-[#D2A12D];
  }

  .implementation {
    @apply bg-[#4EE706];
  }

  .awarded {
    @apply bg-[#4EE706];
  }

  .deffered {
    @apply bg-red-alert;
  }

  .rejected {
    @apply bg-red-alert;
  }
}
